var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        href: "javascript:void(0);",
        "data-toggle": "popover",
        title: "Verfügbare Sonderangebote",
        "data-trigger": "click",
        "data-placement": "top",
        "data-html": "true",
        "data-content": _vm.getOfferText
      }
    },
    [
      _c("span", { staticClass: "offer-left" }, [
        _vm._v("Verfügbare Sonderangebote")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }